<template>
  <div ref='mapContainer' class='map-container'></div>
</template>

<script>
import axios from 'axios'
import { websiteMainUrl } from '@/services/url.service'

export default {
  props: ['modelValue', 'polygonCoordinates'],
  name: 'BaseMap',
  data: () => ({
    location: {
      lng: 46.7179,
      lat: 24.6402,
      bearing: 0,
      pitch: 0,
      zoom: 14.08,
    },
    query: '',
    places: [],
    marker: null,
    address: null,
    country: null,
    region: null,
    locality: null,
    place: null,
    mapLoaded: false, // Track if the map is fully loaded
  }),
  mounted() {
    this.loadMapboxScript().then(() => {
      mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN // Replace with your actual access token
      mapboxgl.setRTLTextPlugin(
        'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
        null,
        true, // Lazy load the plugin
      )

      const { lng, lat, zoom, bearing, pitch } = this.location

      const bounds = new mapboxgl.LngLatBounds(
        new mapboxgl.LngLat(34.4949, 16.8522), // Southwest coordinates of Saudi Arabia
        new mapboxgl.LngLat(55.6667, 32.1546),
      )

      const map = new mapboxgl.Map({
        container: this.$refs.mapContainer,
        style: 'mapbox://styles/mapbox/satellite-streets-v12',
        center: [lng, lat],
        marker: true,
        maxBounds: bounds,
        language: 'ar',
        bearing,
        pitch,
        zoom,
        attributionControl: false, // Remove attribution control if needed
        logoPosition: 'bottom-right', // Adjust logo position if needed
        preserveDrawingBuffer: true, // Preserve drawing buffer if needed
        antialias: true, // Enable antialiasing if needed
        localIdeographFontFamily: 'sans-serif', // Set font family for RTL
        renderWorldCopies: false, // Disable rendering multiple world copies if needed
        fadeDuration: 0, // Set fade duration to 0 if needed
      })

      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
      })

      geocoder.addTo(map)
      geocoder.on('result', (e) => {
        this.goToPlace(e.result)
      })

      map.addControl(
        new mapboxgl.NavigationControl({
          showCompass: false,
        }),
      )

      this.marker = new mapboxgl.Marker({
        draggable: false, // If you want the marker to be draggable
      })

      const draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true,
        },
        styles: [
          // ACTIVE (being drawn)
          // polygon fill
          {
            id: 'gl-draw-polygon-fill',
            type: 'fill',
            filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
            paint: {
              'fill-color': '#D20C0C', // Change this to your desired color
              'fill-outline-color': '#D20C0C', // Change this to your desired color
              'fill-opacity': 0.5,
            },
          },
          // polygon outline stroke
          {
            id: 'gl-draw-polygon-stroke-active',
            type: 'line',
            filter: ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
            layout: {
              'line-cap': 'round',
              'line-join': 'round',
            },
            paint: {
              'line-color': '#D20C0C', // Change this to your desired color
              'line-dasharray': [0.2, 2],
              'line-width': 4,
            },
          },
          // vertex points
          {
            id: 'gl-draw-polygon-and-line-vertex-active',
            type: 'circle',
            filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
            paint: {
              'circle-radius': 8,
              'circle-color': '#e4e143', // Change this to your desired color
            },
          },
        ],
      })

      map.addControl(draw)

      map.on('draw.create', (event) => {
        if (this.map.getLayer('polygon')) {
          this.map.removeLayer('polygon')
        }
        let polygonData = event.features[0].geometry // You can fetch the data and perform operations
        polygonData.center = this.calculatePolygonCenter(polygonData)
        polygonData.radius = this.calculatePolygonRadius(polygonData.center, polygonData.coordinates[0])
        this.$emit('polygonData', polygonData)
        // this.fetchPolygonData(polygonData);
      })


      // Listen for the click event on the map
      map.on('click', (e) => {
        const { lng, lat } = e.lngLat
        this.placeMarker({ lng, lat })
        // this.fetchLocationDetails({ lng, lat })
      })

      // Wait for the map to fully load before allowing operations
      map.on('load', () => {
        this.mapLoaded = true
        if (this.polygonCoordinates) {
          this.addPolygonLayer(this.map)
        }
      })

      class CustomControl {
        constructor(parentComponent) {
          this.parentComponent = parentComponent;
        }

        onAdd(map) {
          this._map = map
          this._container = document.createElement('div')
          this._container.className = 'mapboxgl-ctrl custom-controls'
          // this._container.textContent = 'Custom Button'
          this._container.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-x-octagon-fill" viewBox="0 0 16 16">\n' +
            '  <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353zm-6.106 4.5L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708"/>\n' +
            '</svg>' // Assuming using FontAwesome for the pen icon
          this._container.title = 'Clear Drew Polygon'
          this._container.onclick = () => {

            if (map.getLayer('polygon')) {
              this.parentComponent.clearPolygonLayer();
              map.removeLayer('polygon')
            }
          }
          return this._container
        }

        onRemove() {
          this._container.parentNode.removeChild(this._container)
          this._map = undefined
        }
      }

      map.addControl(new CustomControl(this), 'top-right')

      this.map = map
    })
  },

  unmounted() {
    this.map.remove()
    this.map = null
  },

  watch: {
    modelValue(next) {
      const curr = this.getLocation()
      const map = this.map

      if (curr.lng != next.lng || curr.lat != next.lat) {
        map.setCenter({ lng: next.lng, lat: next.lat })
      }
      if (curr.pitch != next.pitch) map.setPitch(next.pitch)
      if (curr.bearing != next.bearing) map.setBearing(next.bearing)
      if (curr.zoom != next.zoom) map.setZoom(next.zoom)
    },
    polygonCoordinates(val) {
      if (this.mapLoaded && val) { // Check if polygonCoordinates are defined
        this.addPolygonLayer(this.map)
      } else {
        if (this.map && this.map.getLayer('polygon')) { // Check if this.map is defined
          this.map.removeLayer('polygon')
        }
      }
    },

  },

  methods: {
    clearPolygonLayer(){
       this.$emit('polygonData', null)
    },
    placeMarker(lngLat) {
      // Set the marker's location


      this.marker.setLngLat(lngLat)

      // Add the marker to the map
      this.marker.addTo(this.map)
    },
    goToPlaceExist(place) {
      this.map.flyTo({
        center: place,
        bearing: 0,
        pitch: 0,
      })

      const lngLat = { lng: place[0], lat: place[1] }

      this.placeMarker(lngLat)

    },

    loadMapboxScript() {
      const scriptUrls = [
        'https://api.mapbox.com/mapbox-gl-js/v3.0.1/mapbox-gl.js',
        'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v5.0.0/mapbox-gl-geocoder.min.js',
        'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-language/v0.10.1/mapbox-gl-language.js',
        'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-draw/v1.3.0/mapbox-gl-draw.js', // Add Mapbox GL Draw library
        // 'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
      ]

      const styleUrls = [
        'https://api.mapbox.com/mapbox-gl-js/v3.0.1/mapbox-gl.css',
        'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v5.0.0/mapbox-gl-geocoder.css',
        'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-draw/v1.3.0/mapbox-gl-draw.css', // Add Mapbox GL Draw styles
      ]

      const loadScript = (url) => {
        return new Promise((resolve, reject) => {
          const script = document.createElement('script')
          script.src = url
          script.onload = resolve
          script.onerror = reject
          document.head.appendChild(script)
        })
      }

      const loadStylesheet = (url) => {
        const style = document.createElement('link')
        style.href = url
        style.rel = 'stylesheet'
        document.head.appendChild(style)
      }

      const scriptPromises = scriptUrls.map(loadScript)
      const stylePromises = styleUrls.map(loadStylesheet)

      return Promise.all([...scriptPromises, ...stylePromises])
    },
    getLocation() {
      return {
        ...this.map.getCenter(),
        bearing: this.map.getBearing(),
        pitch: this.map.getPitch(),
        zoom: this.map.getZoom(),
      }
    },
    goToPlace(place) {
      this.$emit('update:modelValue', place.center)
      this.map.flyTo({
        center: place.center,
        zoom: 14,
        bearing: 0,
        pitch: 0,
      })

      const lngLat = { lng: place.center[0], lat: place.center[1] }
      this.placeMarker(lngLat)
      // this.fetchLocationDetails(lngLat)

      // Clear the search results
      this.query = ''
      this.places = []
    },
    addPolygonLayer(map) {
      if (map.getSource('polygon')) {
        map.removeSource('polygon')
      }
      map.addSource('polygon', {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: this.polygonCoordinates?.coordinates,
          },
        },
      })

      map.addLayer({
        id: 'polygon',
        type: 'fill',
        source: 'polygon',
        layout: {},
        paint: {
          'fill-color': '#c00b0b',
          'fill-opacity': 0.5,
        },
      })

      if (this.polygonCoordinates?.center) {
        this.goToPlaceExist(this.polygonCoordinates?.center)
      }
    },
    calculatePolygonCenter(polygonCoordinates) {
      const coordinates = polygonCoordinates.coordinates[0]
      const totalPoints = coordinates.length
      let totalLat = 0
      let totalLng = 0

      for (let i = 0; i < totalPoints; i++) {
        totalLat += parseFloat(coordinates[i][1])
        totalLng += parseFloat(coordinates[i][0])
      }

      const centerLat = totalLat / totalPoints
      const centerLng = totalLng / totalPoints
      return [centerLng, centerLat]
    },
    calculatePolygonRadius(center, coordinates) {
      let maxDistance = 0
      // Calculate distance from center to each vertex and find the maximum
      coordinates.forEach((vertex) => {
        const distance = this.calculateDistance(center, vertex)
        if (distance > maxDistance) {
          maxDistance = distance
        }
      })
      return maxDistance
    },
    calculateDistance(point1, point2) {
      // Calculate distance between two points using Pythagoras theorem
      const dx = point2[0] - point1[0]
      const dy = point2[1] - point1[1]
      return Math.sqrt(dx * dx + dy * dy)
    },

  },
}
</script>

<style>
.map-container {
  flex: 1;
  height: 64vh;
}

.custom-controls {
  background: #fff;
  width: 29px;
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  cursor: pointer;
  border-radius: 3px;
  color: black;
  fill: black;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}
</style>
